import { graphql } from 'msw';

import rapidConsoleSeriesDetails from '@features/dashboard/pages/rapid/pages/console/components/TraderView/fixtures/data.json';
import rapidConsoleSeriesTitle from '@features/dashboard/pages/rapid/pages/console/fixtures/data.json';

import seriesMock from '@features/dashboard/pages/central-data/pages/series-details/fixtures/data.json';
import historicalSeries from '@features/competitor/pages/match-history-viewer/components/MatchList/fixtures/data.json';
import historicalSeriesPageTwo from '@features/competitor/pages/match-history-viewer/components/MatchList/fixtures/pageTwo.json';

import tournamentMock from '@features/dashboard/pages/central-data/pages/tournament/fixtures/data.json';
import tournamentsMockPageOne from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/data.json';
import tournamentsMockPageTwo from '@features/dashboard/pages/central-data/pages/tournaments/fixtures/secondPage.json';

import teamMock from '@features/dashboard/pages/central-data/pages/team/fixtures/data.json';
import teamsMockPageOne from '@features/dashboard/pages/central-data/pages/teams/fixtures/firstPage.json';
import teamsMockPageTwo from '@features/dashboard/pages/central-data/pages/teams/fixtures/secondPage.json';
import filteredTeamMock from '@features/dashboard/pages/central-data/pages/teams/fixtures/filteredSecond.json';
import teamsExternalMock from '@features/dashboard/pages/central-data/pages/teams/fixtures/externalAccessibleData.json';
import titleMock from '@features/dashboard/pages/central-data/pages/title/fixtures/data.json';
import titlesMock from '@features/dashboard/pages/central-data/pages/titles/fixtures/data.json';
import playersMock from '@features/dashboard/pages/central-data/pages/players/fixtures/data.json';
import playersPageTwo from '@features/dashboard/pages/central-data/pages/team/fixtures/playersPageTwo.json';
import playersFilteredMock from '@features/dashboard/pages/central-data/pages/players/fixtures/filtered.json';
import playerMock from '@features/dashboard/pages/central-data/pages/players/components/EditPlayerModal/fixtures/data.json';

import dataProvidersList from '@features/dashboard/pages/central-data/fixtures/dataSourcesList.json';

import { MIN_SEARCH_LENGTH } from '@components/Forms/SearchBar';

const externalEndpoint = graphql.link('http://localhost:3001/central-data/graphql');

/**
 * Util to turn off delay when running tests
 */
const ms = (duration: number) => (process.env.NODE_ENV === 'test' ? 0 : duration);

const handlers = [
    externalEndpoint.query('GetSeriesById', (req, res, ctx) => {
        const { externalLinks, machineDataSource, id, ...rest } = seriesMock.data.series;

        return res(
            ctx.data({
                series: {
                    id: req.variables.id,
                    ...rest,
                },
            }),
        );
    }),
    externalEndpoint.query('GetSeriesTeamsColor', (req, res, ctx) =>
        res(
            ctx.data({
                series: seriesMock.data.series,
            }),
        ),
    ),
    externalEndpoint.query('GetSeriesByIdSeriesOverview', (req, res, ctx) => {
        const { externalLinks, machineDataSource, id, ...rest } = seriesMock.data.series;

        return res(
            ctx.data({
                series: {
                    id: req.variables.id,
                    ...rest,
                },
            }),
        );
    }),
    externalEndpoint.query('GetHistoricalSeries', (req, res, ctx) => {
        if (!req.variables.after) {
            return res(ctx.delay(ms(100)), ctx.data(historicalSeries.data));
        }
        return res(ctx.delay(ms(100)), ctx.data(historicalSeriesPageTwo.data));
    }),
    externalEndpoint.query('GetRapidConsoleSeriesDetails', (_, res, ctx) =>
        res(ctx.data(rapidConsoleSeriesDetails.data)),
    ),

    externalEndpoint.query('GetRapidConsoleSeriesTitle', (_, res, ctx) =>
        res(ctx.data(rapidConsoleSeriesTitle.data)),
    ),

    externalEndpoint.query('GetTitleById', (_, res, ctx) => res(ctx.data(titleMock.data))),

    externalEndpoint.query('GetTitles', (_, res, ctx) => res(ctx.data(titlesMock.data))),
    externalEndpoint.query('GetFilterTitles', (_, res, ctx) => res(ctx.data(titlesMock.data))),

    externalEndpoint.query('GetTitlesManagementData', (_, res, ctx) =>
        res(ctx.data(titlesMock.data)),
    ),

    externalEndpoint.query('GetTeamsFilter', (_, res, ctx) => res(ctx.data(teamsMockPageOne.data))),
    externalEndpoint.query('GetTeamsExternal', (_, res, ctx) =>
        res(ctx.data(teamsExternalMock.data)),
    ),
    externalEndpoint.query('GetTeamById', (req, res, ctx) => {
        const teamMockWithId = teamMock.data;
        teamMockWithId.team.id = req.variables.teamId;
        return res(ctx.data(teamMockWithId));
    }),
    externalEndpoint.query('GetAutoCompleteTeams', (req, res, ctx) => {
        if (req.variables.titleId === '1') {
            return res(ctx.data(filteredTeamMock.data));
        }
        if (!req.variables.after) {
            return res(ctx.data(teamsMockPageOne.data));
        }
        return res(ctx.data(teamsMockPageTwo.data));
    }),

    externalEndpoint.query('GetTournamentsFilter', (_, res, ctx) =>
        res(ctx.data(tournamentsMockPageOne.data)),
    ),
    externalEndpoint.query('GetTournamentById', (_, res, ctx) =>
        res(ctx.data({ tournament: tournamentMock })),
    ),
    externalEndpoint.query('GetAutoCompleteTournaments', (req, res, ctx) => {
        if (!req.variables.after) {
            return res(ctx.data(tournamentsMockPageOne.data));
        }
        return res(ctx.data(tournamentsMockPageTwo.data));
    }),

    externalEndpoint.query('GetPlayersExternal', (req, res, ctx) => {
        const responseData = {
            players: {
                ...playersMock.data,
                edges: [
                    ...playersMock.data.players.edges.map((edge) => ({
                        ...edge,
                        node: (() => {
                            const { externalLinks, ...rest } = edge.node;
                            return rest;
                        })(),
                    })),
                ],
            },
        };

        return res(ctx.data(responseData));
    }),

    externalEndpoint.query('GetPlayerById', (req, res, ctx) => {
        if (req?.variables?.playerId === '10') {
            return res(ctx.data({ playerId: playerMock }));
        }
        return res(ctx.data({ playerId: null }));
    }),

    externalEndpoint.query('GetPlayersFilter', (req, res, ctx) => {
        if (req?.variables?.nickname?.contains?.length >= MIN_SEARCH_LENGTH) {
            return res(ctx.data(playersFilteredMock.data));
        }
        if (req.variables.after) {
            return res(ctx.data(playersPageTwo.data));
        }
        return res(ctx.data(playersMock.data));
    }),

    externalEndpoint.mutation('CreateSdkDemoSeries', (_, res, ctx) =>
        res(
            ctx.data({
                createSeries: {
                    createdSeries: {
                        id: '1',
                    },
                },
            }),
        ),
    ),

    externalEndpoint.query('GetDataProviders', (_, res, ctx) =>
        res(ctx.data(dataProvidersList.data)),
    ),
];

export default handlers;

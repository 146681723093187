import { graphql } from 'msw';

const userConfigInternalLink = graphql.link('http://localhost:3003/user-configurations/graphql');

export default [
    userConfigInternalLink.query('GetUserSdkConfiguration', (req, res, ctx) =>
        res(
            ctx.data({
                configurations: {
                    sdk: {
                        title: {
                            sdkSingleTitle: {
                                titleShortName: 'r6',
                            },
                        },
                    },
                },
            }),
        ),
    ),
];
